/* Provide sufficient contrast against white background */

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.text-warning-dark {
  color: #db5200 !important
}

.custom-alert{
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    color: #818182;
    background-color: #fefefe;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin: 1rem 0px;
    border: 1px solid transparent;
    border-bottom: 2px solid #E01A76;
    border-radius: 0.25rem;
    transition: opacity 0.15s linear;
}
.custom-alert > p{
    padding: 5px 0px !important;
    margin: 0 !important;
}
.custom-alert > p > button{
  cursor: pointer;
  color: #007bff !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  --bs-text-opacity: 1;
  float: right !important;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
}

.details-box{
  border-left: #000 4px solid;
  border-right: #000 2px solid;
  border-bottom: #000 1px solid;
  border-top: #000 1px solid;
}

.link{
  color: #fff;
  background-color: #007bff;
  padding: 2px 15px;
  margin: 2px;
}
.link:hover{
  background-color: #0366d6;
  color: #fff;
  border-radius: 5px;
}

.description{
  color: #686767;
}
.lst{
  border-bottom: #939393 2px solid;
  margin-bottom: 2px;
}


nav{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 350px;
  padding: 10px 14px;
  color: var(--panel-color);
  background-color: var(--text-color);
  border-right: 1px solid var(--border-color);
  transition: var(--tran-05);
  overflow: auto;
  z-index: 1000000000;
}
nav.close{
  width: 0px;
  display: none;
}
nav .logo-name{
  display: flex;
  align-items: center;
}
nav .logo-image{
  display: flex;
  justify-content: center;
  max-width: 80%;
}
nav .logo-image img{
  /* width: 40px; */
  /* object-fit: cover; */
  border-radius: 25px;
  max-height: 100px;
  max-width: 100%;
}
.sidebar-toggle1{
  cursor: pointer;
}

.nav-input{
  max-height: 35px;
}

nav .logo-name .logo_name{
  font-size: 22px;
  font-weight: 600;
  color: var(--title-icon-color);
  margin-left: 14px;
  transition: var(--tran-05);
}
nav.close .logo_name{
  /* opacity: 0; */
  pointer-events: none;
}
nav .menu-items{
  margin-top: 40px;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-items li{
  list-style: none;
}
.menu-items li a{
  display: flex;
  align-items: center;
  height: 50px;
  text-decoration: none;
  position: relative;
  /* border-bottom: solid 2px #ddd; */
}
.menu-items li a:hover{
  border-bottom: solid 2px #ddd;
}
.nav-links li:hover, .logout-mode li:hover{
  background-color: #ffffff;
  color: #000;
  border-radius: 5px;
  list-style: none;
  border-left: 5px solid var(--primary-color);
}
body.dark li a:hover:before{
  background-color: var(--text-color);
}
.dropdown-item{
  background-color: #eee;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: small;
  color: #2000ff;
  padding-left: 15%;
}
.dropdown-item:hover{
  background-color: #e0d7d7;
  color: #007bff;
}
.menu-items li a i{
  font-size: 18px;
  min-width: 45px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black-light-main-color);
}
.menu-items li a .link-name{
  font-size: 18px;
  font-weight: 400;
  color: var(--black-light-main-color);    
  transition: var(--tran-05);
}
nav.close li a .link-name{
  /* opacity: 0; */
  pointer-events: none;
}
.nav-links li a:hover i,
.nav-links li a:hover .link-name{
  color: var(--primary-color);
}
body.dark .nav-links li a:hover i,
body.dark .nav-links li a:hover .link-name{
  color: var(--text-color);
}
.menu-items .logout-mode{
  padding-top: 10px;
  border-top: 1px solid var(--border-color);
  margin-top: 10vh;
}
.menu-items .mode{
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.menu-items .mode-toggle{
  position: absolute;
  right: 14px;
  height: 50px;
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mode-toggle .switch{
  position: relative;
  display: inline-block;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
}
.switch:before{
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  background-color: var(--panel-color);
  border-radius: 50%;
  transition: var(--tran-03);
}
body.dark .switch:before{
  left: 20px;
}

.dashboard{
  position: relative;
  /* left: 250px; */
  background-color: var(--panel-color);
  /* min-height: 100vh; */
  /* width: calc(100% - 250px); */
  width: 100%;
  padding: 10px 14px;
  transition: var(--tran-05);
}
nav.close ~ .dashboard{
  left: 0px;
  width: 100%;
}
.dashboard .top{
  position: fixed;
  top: 0;
  /* left: 250px; */
  display: flex;
  /* width: calc(100% - 250px); */
   width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  background-color: var(--text-color);
  color: var(--gold-color);
  transition: var(--tran-05);
  z-index: 10;
}
nav.close ~ .dashboard .top{
  left: 0px;
  width: 100%;
}
.dashboard .top .sidebar-toggle{
  font-size: 26px;
  color: var(--black-light-main-color);
  cursor: pointer;
}
.dashboard .top .search-box{
  position: relative;
  height: 45px;
  max-width: 300px;
  width: 100%;
  margin: 0 30px;
}
.top .search-box input{
  position: absolute;
  border: 1px solid var(--border-color);
  background-color: var(--panel-color);
  padding: 0 25px 0 50px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  color: var(--text-color);
  font-size: 15px;
  font-weight: 400;
  outline: none;
}
.top .search-box i{
  position: absolute;
  left: 15px;
  font-size: 22px;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  color: var(--black-light-color);
}

.dashboard .dash-content{
  padding-top: 15px;
}
.dash-content .title{
  display: flex;
  align-items: center;
  margin: 60px 0 30px 0;
}
.dash-content .title i{
  position: relative;
  height: 35px;
  width: 35px;
  background-color: var(--primary-color);
  border-radius: 6px;
  color: var(--title-icon-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.dash-content .title .text{
  font-size: 24px;
  font-weight: 500;
  color: var(--text-color);
  margin-left: 10px;
}
.dash-content .box{
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 2px rgb(75, 75, 75) solid;
  padding: 15px 20px;
  background-color: #0D6EFD;
  transition: var(--tran-05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.dash-content .box:hover{
  background-color: var(--black-light-main-color);
  box-shadow: none;
}
.box i{
  font-size: 35px;
  color: var(--text-color);
}
.box .text{
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
}
.box .number{
  font-size: 40px;
  font-weight: 500;
  color: var(--text-color);
}
.box.box2{
  background-color: #FFACAC;
}
.box.box3{
  background-color: var(--box3-color);
}
.dash-content .activity .activity-data{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.card-header{
  display: flow-root;
}
h6>a{
  margin: 2px;
}

.control-label{
  display: block;
}
input[type=text],input[type=date]{
  border: #1b6ec2 1px solid;
}
input[type=text]:disabled,input[type=date]:disabled{
  border: rgb(79, 79, 79) 1px solid;
}

button>span, a>i{
  margin: 3px;
}

.form-control-sm{
  max-width: 130px;
}

.bg-light1 {
  background-color: #fbfbfb !important;
}


.loader{
  min-width: 100%;
  min-height: 100vh;
  position: fixed;
  background-color: rgba(194, 194, 194, 0.91);
  padding-top: 25%;
  z-index: 1000;
}
.loader > .loading {
  border: 5px solid #c2c2c2; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-bottom: 5px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}