.nav-m{
  background-color: #fff;
  color: #000;
  border-bottom: rgb(211, 211, 211) 1px solid;
  padding: 15px 10px;
  top: 0px;
  position: fixed;
  max-height: 50px;
  width: 100%;
  z-index: 1000;
  display: flex;
  height: 50px;
}

.nav-m-a{
  font-weight: 200;
  font-size: large;
  padding: 0px 0px 0px 5px;
  min-height: 100%;
  justify-content: baseline;
}
toggler{
  right: 10px;
  position: absolute;
}
collapser{
  float:right;
  text-align: center;
  justify-content: center;
  min-width: min-content;
  padding: 0px 5px;
  display: flex;
  flex-direction: row;
  right: 5%;
  position: absolute;
}
@media only screen and (max-width:840px) {
  collapser{
    display: none;
  }
}
a{
  margin: 0px 2px;
}
.defualt-a{
  background-color: #565add;
  /* border-radius: 15px; */
  padding: 2px 10px;
  color: #fff;
}
.defualt-a:hover{
  color: rgb(215, 213, 213);
  box-shadow: 0 .25rem .75rem #8287e0a5;
}
.sec-a{
  background-color: rgb(16, 189, 116);
  /* border-radius: 15px; */
  padding: 2px 10px;
  color: #fff;
}
.sec-a:hover{
  color: #565add;
  box-shadow: 0 .25rem .75rem #8287e0a5;
}
.collapser-a{
  color: rgb(13, 133, 232);
  padding: 0px 10px;
  display: inline flow-root list-item;
}
.collapser-a:hover{
  background-color: #565add;
  border-radius: 15px;
  padding: 2px 10px;
  color: #fff;
  box-shadow: 0 .25rem .75rem #8287e0a5;
}
.img-header-logo{
  max-height: 20px;
}


a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
